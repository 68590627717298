// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";

const config = {
	useSystemColorMode: false,
	initialColorMode: "light",
};

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
	blue: {
		50: "#F0FCFF",
		100: "#BDF3FF",
		200: "#8AEAFF",
		300: "#57E1FF",
		400: "#24D8FF",
		500: "#00C5F0",
		600: "#009DBF",
		700: "#00718A",
		800: "#004757",
		900: "#001D24",
	},
	yellow: {
		50: "#FFF7CC",
		100: "#FFEF99",
		200: "#FFE866",
		300: "#FFE033",
		400: "#FFD800",
		500: "#E5C300",
		600: "#CCAD00",
		700: "#B29700",
		800: "#998200",
		900: "#806C00",
	},
};

const sizes = {
	container: {
		sm: "640px",
		md: "768px",
		lg: "1024px",
		xl: "1280px",
		xxl: "1920px",
	},
};

const fontSizes = {
	xs: "0.75rem",
	sm: "0.875rem",
	md: "1rem",
	lg: "1.125rem",
	xl: "1.25rem",
	"2xl": "1.5rem",
	"3xl": "1.875rem",
	"4xl": "2.25rem",
	"5xl": "3rem",
	"6xl": "3.75rem",
	"7xl": "4.5rem",
	"8xl": "6rem",
	"9xl": "8rem",
};

const components = {};

const theme = extendTheme({
	config,
	styles: {
		global: {
			p: {
				lineHeight: "1.8",
			},
			html: {
				scrollBehaviour: "smooth",
			},
		},
	},
	fonts: {
		body: "'Open Sans', sans",
		heading: "'Open Sans', sans",
		// mono: "Menlo, monospace",
	},
	colors,
	components,
	sizes,
	fontSizes,
});

export default theme;
